'use client';

import clsx from 'clsx';
import { GDPR } from '@/components/GDPR';
import { type FC, useState } from 'react';
import type { AnalyticsData } from '@/types/entities';
import { useSiteContext } from '@/context/SiteContext';
import { useGaContext } from '@/context/GoogleAnalyticsContext';

import './video-player.css';

import Placeholder from '@/assets/surfer/images/placeholder.jpg';
import { useVideoPlayerContext } from '@/context/VideoPlayerContext';
import { generateJWPlayerScript } from '@/utils/generate-jwplayer-script';
import JWPlayer, {
	type MuteEvent,
	type TimeEvent,
	type ConfigProps,
} from '@jwplayer/jwplayer-react';

import styles from './styles.module.css';

export interface VideoPlayerProps {
	analyticsData: AnalyticsData;
	bottomSpacing?: boolean;
	disableAutoplay?: boolean;
	mode?: 'contain' | 'cover';
	options?: ConfigProps;
	videoId: string;
	videoImage?: string;
	videoSrc: string;
	videoTitle: string;
}

const VideoPlayerComponent: FC<VideoPlayerProps> = ({
	analyticsData,
	bottomSpacing = true,
	disableAutoplay = false,
	mode = 'contain',
	options,
	videoId,
	videoImage,
	videoSrc,
	videoTitle,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const { config } = useSiteContext();
	const { sendGaEvent } = useGaContext();
	const { firstVideoPlayed, handleOnFirstVideoPlay } = useVideoPlayerContext();

	const isCandidateToPlay = !firstVideoPlayed || firstVideoPlayed === videoId;
	const autoplayDisabled = disableAutoplay || options?.autostart === false;
	const autostart = !autoplayDisabled && isCandidateToPlay ? 'viewable' : false;
	const plugins = {
		'//cdn-ext.spiny.ai/spec/jwp/pandora-jwp.js': {},
	};
	const jwplayerScript = generateJWPlayerScript(config);

	const handleIsLoaded = () => {
		setIsLoading(false);
	};

	const handleOnFirstFrame = () => {
		sendGaEvent('video_play_jw', {
			videoId,
			videoTitle,
			...analyticsData,
		});

		if (!firstVideoPlayed) {
			handleOnFirstVideoPlay(videoId);
		}
	};

	const handleOnMute = ({ mute }: MuteEvent) => {
		sendGaEvent(`video_${mute ? 'mute' : 'unmute'}`, {
			videoId,
			videoTitle,
			...analyticsData,
		});
	};

	const handleOnTime = ({ duration, position }: TimeEvent) => {
		const played = position === duration;

		if (played) {
			sendGaEvent('video_complete_jw', {
				videoId,
				videoTitle,
				...analyticsData,
			});
		}
	};

	if (!jwplayerScript) {
		return null;
	}

	return (
		<div
			className={clsx({
				[styles.jwPlayerBottomSpacing]: bottomSpacing,
				[styles.jwPlayerContainerLoading]: isLoading,
				'video-cover': mode === 'cover',
			})}
		>
			<JWPlayer
				config={{
					...options,
					autostart,
					plugins,
				}}
				file={videoSrc}
				image={videoImage || Placeholder.src}
				key={autostart.toString()}
				library={jwplayerScript}
				onFirstFrame={handleOnFirstFrame}
				onMute={handleOnMute}
				onReady={handleIsLoaded}
				onTime={handleOnTime}
			/>
		</div>
	);
};

export const VideoPlayer: FC<VideoPlayerProps> = ({
	bottomSpacing = true,
	disableAutoplay = false,
	mode = 'contain',
	...props
}) => (
	<GDPR purposes={['content']} vendorId="jwplayer">
		<VideoPlayerComponent
			bottomSpacing={bottomSpacing}
			disableAutoplay={disableAutoplay}
			mode={mode}
			{...props}
		/>
	</GDPR>
);
