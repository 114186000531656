import { parseToUtc } from '@/utils/dates/parse-to-utc';

/**
 * The `formatReadableDate` function in TypeScript formats a given date string into a readable date
 * format using the specified options and locale. This assumes the date is in UTC.
 * @param {string} dateString - The `dateString` parameter is a string representing a date that you
 * want to format into a readable date format.
 * @param [options] - The `options` parameter in the `formatReadableDate` function allows you to
 * specify additional formatting options for the date output. These options can include properties like
 * `hour`, `minute`, `second`, `weekday`, `era`, `timeZoneName`, and more. By passing in an object with
 * @param {string} [locale=en-US] - The `locale` parameter in the `formatReadableDate` function is used
 * to specify the language and region for the date formatting. In this case, the default value is set
 * to `'en-US'`, which represents English language and United States region.
 * @returns The function `formatReadableDate` returns a formatted date string based on the input
 * `dateString` using the specified options and locale.
 */
export const formatReadableDate = (
	dateString: string,
	options?: Intl.DateTimeFormatOptions,
	locale: string = 'en-US',
) => {
	const stringDate = parseToUtc(dateString);

	return new Intl.DateTimeFormat(locale, {
		day: 'numeric',
		month: 'long',
		timeZone: 'UTC',
		year: 'numeric',
		...options,
	}).format(stringDate);
};
